import { CONSTANT } from "../core/static-constant";

export const CommonService = {
  isUndefinedOrNull,
  isObjEmpty,
  checkIsInvalidForm,
  formatDate,
  displayDate,
  getBadgeColorByType,
  twoDatesEqual,
};

/*
Check Undefined and null value
*/
function isUndefinedOrNull(value) {
  if (typeof value === CONSTANT.UNDEFINED || value === null || value === "") {
    return true;
  } else {
    return false;
  }
}

/*
To check object is empty or not.
*/
function isObjEmpty(obj) {
  return (
    obj === null || typeof obj === "undefined" || Object.keys(obj).length === 0
  );
}

/*
To Check Form valid or not.
*/
async function checkIsInvalidForm(requiredField, obj) {
  if (isObjEmpty(obj)) {
    return true;
  } else {
    let value = false;
    requiredField.forEach((field) => {
      if (isUndefinedOrNull(obj[field]) && value === false) {
        value = true;
      }
    });
    return value;
  }
}

/*
To Format Date.
*/
function formatDate(date) {
  if (isUndefinedOrNull(date)) {
    return "";
  } else {
    return new Date(date).toLocaleDateString("en-us", {
      day: "numeric",
      year: "numeric",
      month: "short",
    });
  }
}

/*
To Display Date.
*/
function displayDate(date) {
  if (isUndefinedOrNull(date)) {
    return "";
  } else {
    return new Date(date).toLocaleDateString("en-us", {
      day: "numeric",
      year: "numeric",
      month: "short",
    });
  }
}

/*
To Display Date.
*/
function getBadgeColorByType(value) {
  return isUndefinedOrNull(value)
    ? "#000000"
    : value.indexOf("Created")
    ? "successBadge"
    : value.indexOf("Updated")
    ? "primaryBadge"
    : "dangerBadge";
}

/*
To Compare two dates.
*/
function twoDatesEqual(a, b){
  const date1 = new Date(a);
  const date2 = new Date(b);
  return (!isNaN(date1.getTime()) && !isNaN(date2.getTime()))? (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  ) : false;
}