import "./App.css";

// Chat Styles
import "./styles/chat.css";
// Globals Styles
import "./styles/globals.css";
// Rtl Styles
import "./styles/rtl.css";
// Dark Mode Styles
import "./styles/dark.css";
// Left Sidebar Dark Mode Styles
import "./styles/leftSidebarDark.css";
// Theme Styles
import React from "react";

import {
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from "./container/dashboard/dashboard";
import ActiveLogs from "./container/active_logs";
import AccessCode from "./container/access_code/access_code";
import RedirectUrl from "./container/redirect_url/redirect_url";
import Actions from "./components/Ui Elements/Action";

const router = [
  { path: "/", element: <Dashboard /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/active-logs/:locationId", element: <ActiveLogs /> },
  { path: "/redirect_url", element: <RedirectUrl /> },
  { path: "/access-code", element: <AccessCode /> },
  { path: "/uielements", element: <Actions /> },
];

function App() {
  return (
    <>
      <div className={`main-wrapper-content active`}>
        <Routes>
          {router.map((a, i) => (
            <Route key={i} path={a.path} element={a.element} />
          ))}
        </Routes>
      </div>
    </>
  );
}

export default App;
