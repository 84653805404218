import React from "react";
import { Box, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { lightBlue } from "@mui/material/colors";
import { Active_Logs_Service } from "../../service/active_logs_service";
import { Auth_Location_Service } from "../../service/auth_locations_service";
import { CommonService } from "../../service/common_service";
import { CONSTANT } from "../../core/static-constant";
import { ACTIVE_LOGS_COLUMNS } from "../../core/columns";
import Link from "@mui/material/Link";
import ToastComponent from "../Toast-Component/ToastComponent";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  color: "#757FEF",
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  right: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "5",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 100,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: 0,
  marginLeft: "15px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginRight: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    backgroundColor: "#F5F7FA",
    borderRadius: "30px",
    padding: theme.spacing(1.4, 0, 1.4, 2),
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "260px",
      "&:focus": {
        width: "280px",
      },
    },
  },
}));

function getBadgeColorByType(value) {
  return !value
    ? "#000000"
    : value.indexOf("Create") >= 0
    ? "successBadge"
    : value.indexOf("Update") >= 0
    ? "primaryBadge"
    : value.indexOf("Delete") >= 0
    ? "dangerBadge"
    : "infoBadge";
}

function PagerComponent(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

PagerComponent.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function RenderActionButtons(props) {
  return (
    <>
      <VisibilityIcon
        className="cursor-pointer"
        sx={{ color: lightBlue[500] }}
      />
    </>
  );
}

function createData(
  id,
  name,
  locationName,
  locationId,
  contactId,
  description,
  createdAt,
  eventType
) {
  return {
    id,
    name,
    locationName,
    locationId,
    contactId,
    description,
    createdAt,
    eventType,
  };
}

const ActiveLogsTables = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [allRows, setAllRows] = React.useState([]);
  const [displayRows, setDisplayRows] = React.useState([]);
  const [locationId, setLocationId] = React.useState("");
  const [searchDate, setSearchDate] = React.useState();
  const [searchText, setSearchText] = React.useState();
  const [sorting, setSorting] = React.useState({
    key: "locationName",
    ascending: true,
  });
  const [toastObject, setToastObject] = React.useState({
    type: "",
    toastMessage: "",
    show: false,
  });
  // const params = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  React.useEffect(() => {
    const locId =
      props && props.locationId && props.locationId !== "all"
        ? props.locationId
        : "all";

    function init(locId) {
      setLocationId(locId);

      setIsLoading(true);
      getActiveLogs(locId);
    }
    init(locId);
  }, []);

  const handleDateChange = async (val, event) => {
    await setSearchDate(val);
    filterData(val, searchText);
  };

  const handleSearchChange = async (val, event) => {
    await setSearchText(event.target.value);
    filterData(searchDate, event.target.value);
  };

  const filterData = async (searchDate, searchText) => {
    const allData = [...allRows];
    if (
      searchText ||
      (searchDate && JSON.parse(JSON.stringify(searchDate)) != null)
    ) {
      if (
        searchText &&
        searchDate &&
        JSON.parse(JSON.stringify(searchDate)) != null
      ) {
        const filterRows = allData.filter(function (el) {
          return (
            CommonService.twoDatesEqual(el.createdAt, searchDate) &&
            ((el.eventType && el.eventType.indexOf(searchText) >= 0) ||
              (el.description && el.description.indexOf(searchText) >= 0) ||
              (el.locationName && el.locationName.indexOf(searchText) >= 0) ||
              (el.name && el.name.indexOf(searchText) >= 0))
          );
        });
        setDisplayRows([...filterRows]);
      } else if (
        !searchText &&
        searchDate &&
        JSON.parse(JSON.stringify(searchDate)) != null
      ) {
        const filterRows = allData.filter(function (el) {
          return CommonService.twoDatesEqual(el.createdAt, searchDate);
        });
        setDisplayRows([...filterRows]);
      } else if (
        searchText &&
        (!searchDate || !JSON.parse(JSON.stringify(searchDate)) != null)
      ) {
        const filterRows = allData.filter(function (el) {
          return (
            (el.eventType && el.eventType.indexOf(searchText) >= 0) ||
            (el.description && el.description.indexOf(searchText) >= 0) ||
            (el.locationName && el.locationName.indexOf(searchText) >= 0) ||
            (el.name && el.name.indexOf(searchText) >= 0)
          );
        });
        setDisplayRows([...filterRows]);
      } else {
        setDisplayRows([...allRows]);
      }
    } else {
      setDisplayRows(allRows);
    }
  };

  const handleViewIconClick = async (e, row) => {
    e.stopPropagation();
    const response = await Auth_Location_Service.getLocationUrlByLocationId(
      row.locationId,
      row.contactId
    );
    if (response && response.status === CONSTANT.SUCCESS) {
      const finalLocationUrl = response.finalLocationUrl;
      window.open(finalLocationUrl, "_blank");
    } else {
      setToastObject({
        type: "error",
        toastMessage: response.message,
        show: true,
      });
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - displayRows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getActiveLogs = async (locId) => {
    const response = await Active_Logs_Service.getActiveLogs(
      locId || locationId
    );

    setIsLoading(false);
    if (response && response.status === CONSTANT.SUCCESS) {
      var list = [];
      response.data.forEach((element) => {
        list.push(
          createData(
            element._id,
            element.name,
            element.locationName,
            element.locationId,
            element.contactId,
            element.description,
            element.createdAt,
            element.eventType
          )
        );
      });

      setDisplayRows([...list]);
      setAllRows([...list]);
    } else {
      console.log(response.error || response.message);
    }
  };

  const handleSort = (key, ascending, dataType) => {
    const currentData = [...displayRows];

    setSorting({ key: key, ascending: ascending });
    if (key !== "actions") {
      const sortedData = currentData.sort((a, b) => {
        return dataType === "date"
          ? new Date(a[key]) - new Date(b[key])
          : a[key].toLowerCase().localeCompare(b[key].toLowerCase());
      });
      setDisplayRows(ascending ? [...sortedData] : [...sortedData.reverse()]);
    }
  };

  return (
    <>
      <ToastComponent
        open={toastObject.show}
        setOpen={(event) =>
          setToastObject({
            type: "",
            toastMessage: "",
            show: event,
          })
        }
        message={toastObject.toastMessage}
        severity={toastObject.type}
      />
      <Card
        sx={{
          boxShadow: "none",
          borderRadius: "10px",
          p: "25px 20px 15px",
          mb: "15px",
        }}
      >
        <Box
          sx={{
            paddingBottom: "10px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={3}>
              <Typography
                as="h3"
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                Active Logs
              </Typography>
            </Grid>
            {isLoading === false && (
              <Grid item xs={9} className="pull-right">
                <Search className="search-form" sx={{ my: 2 }}>
                  <SearchIconWrapper
                    sx={{ display: { xs: "none", sm: "inline-flex" } }}
                  >
                    <SearchIcon />
                  </SearchIconWrapper>

                  <StyledInputBase
                    placeholder="Search here.."
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchChange("", e)}
                  />
                </Search>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={searchDate}
                      inputFormat="dd-MM-yyyy"
                      label="Search by date"
                      className="date-picker"
                      onChange={(val, event) => handleDateChange(val, event)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            )}
          </Grid>
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
          }}
        >
          <Table
            sx={{ minWidth: 500 }}
            aria-label="custom pagination table"
            className="dark-table"
          >
            <TableHead sx={{ background: "#F7FAFF" }}>
              <TableRow>
                {ACTIVE_LOGS_COLUMNS &&
                  ACTIVE_LOGS_COLUMNS.map((col, index) => (
                    <TableCell
                      onClick={() =>
                        handleSort(col.field, !sorting.ascending, col.dataType)
                      }
                      key={"header_" + index}
                      sx={{
                        borderBottom: "1px solid #F7FAFF",
                        fontSize: "13.5px",
                        padding: "15px 10px",
                        textAlign: col.textAlign,
                      }}
                    >
                      <Typography
                        as="h5"
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        {col.headerName}{" "}
                        {sorting && col.field === sorting.key ? (
                          sorting.ascending ? (
                            <ArrowUpwardIcon
                              sx={{
                                ml: 1,
                                mt: -0.5,
                              }}
                            />
                          ) : (
                            <ArrowDownwardIcon
                              sx={{
                                ml: 1,
                                mt: -0.5,
                              }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? displayRows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : displayRows
              ).map((row, index) => (
                <TableRow
                  key={row._id}
                  // key={"row_" + index}
                >
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "9px 10px",
                      fontSize: "13px",
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "9px 10px",
                      fontSize: "13px",
                    }}
                  >
                    {row.locationName}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "9px 10px",
                      fontSize: "13px",
                    }}
                  >
                    {CommonService.formatDate(row.createdAt)}
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: 500,
                      borderBottom: "1px solid #F7FAFF",
                      fontSize: "12px",
                      padding: "9px 10px",
                    }}
                  >
                    <span className={getBadgeColorByType(row.eventType)}>
                      {row.eventType}
                    </span>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #F7FAFF",
                      padding: "9px 10px",
                      fontSize: "13px",
                    }}
                  >
                    {row.description}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 500,
                      borderBottom: "1px solid #F7FAFF",
                      fontSize: "12px",
                      padding: "9px 10px",
                    }}
                  >
                    <Link
                      className="text-white"
                      onClick={(e) => handleViewIconClick(e, row)}
                    >
                      <RenderActionButtons />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell
                    colSpan={4}
                    style={{ borderBottom: "1px solid #F7FAFF" }}
                  />
                </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={8}
                  count={displayRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={PagerComponent}
                  style={{ borderBottom: "none" }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default ActiveLogsTables;
