
export const ACTIVE_LOGS_COLUMNS = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      fontSize: "13.5px",
      padding: "15px 10px",
      dataType: "string",
      textAlign: "left",
    },
    {
      field: "locationName",
      headerName: "Location Name",
      width: 350,
      fontSize: "13.5px",
      padding: "15px 10px",
      dataType: "string",
      textAlign: "left",
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 250,
      fontSize: "13.5px",
      padding: "15px 10px",
      dataType: "date",
      textAlign: "left",
    },
    {
      field: "eventType",
      headerName: "Event Type",
      width: 200,
      fontSize: "13.5px",
      padding: "15px 10px",
      dataType: "string",
      textAlign: "left",
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      fontSize: "13.5px",
      padding: "15px 10px",
      dataType: "string",
      textAlign: "left",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      fontSize: "13.5px",
      padding: "15px 10px",
      textAlign: "center",
    },
  ];