export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;
// export const REACT_APP_BASE_URL = "http://3.144.40.28:4000/";
// export const REACT_APP_FRONTEND_BASE_URL = "http://3.144.40.28/";

// export const REACT_APP_BASE_URL = "https://3.144.40.28:4000/";

export const DEFAULT_ORDER = "asc";
export const DEFAULT_ROWS_PER_PAGE = 5;

export const API_CONSTANT = {
  /*auth_loaction*/
  CREATE_AUTH_LOCATION: "authLocation/createAuthLocation",
  GET_LOCATION_URL: "authLocation/getLocationUrlByLocationId",

  /*active_logs*/
  CREATE_ACTIVE_LOGS: "activeLogs/createActiveLogs",
  GET_ACTIVE_LOGS: "activeLogs/getActiveLogs",

  /*access_code*/
  GENERATE_ACCESS_CODE: "authHighlevel/generateAccessCode",
};
