import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const ToastComponent = ({ open, setOpen, message, severity }) => {
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Snackbar
          open={open}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default ToastComponent;
