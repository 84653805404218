import React, { useState } from "react";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";
import { Auth_Location_Service } from "../../service/auth_locations_service";
import { CommonService } from "../../service/common_service";
import { CONSTANT } from "../../core/static-constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import ToastComponent from "../../components/Toast-Component/ToastComponent";

const Dashboard = () => {
  const [isInvalidForm, setIsInvalidForm] = useState(false);
  const [toastObject, setToastObject] = useState({
    type: "",
    toastMessage: "",
    show: false,
  });

  const formik = useFormik({
    initialValues: {
      locationId: "",
      ghlCode: "",
      ghlOwnerName: "",
      locationUrl: "",
      emailId: "",
    },
    validationSchema: Yup.object({
      locationId: Yup.string().required("GHL Location id is required"),
      ghlCode: Yup.string().required("GHL Code is required"),
      ghlOwnerName: Yup.string().required("GHL Owner Name is required"),
      locationUrl: Yup.string().required("Location Url is required"),
      emailId: Yup.string()
        .email("Invalid email id")
        .required("Email Id is required"),
    }),
    onSubmit: async (values, actions) => {
      const authLocationObj = {
        locationId: values.locationId,
        ghlCode: values.ghlCode,
        ghlOwnerName: values.ghlOwnerName,
        locationUrl: values.locationUrl,
        emailId: values.emailId,
      };
      const response = await Auth_Location_Service.addAuthLocation(
        authLocationObj
      );
      if (response && response.status === CONSTANT.SUCCESS) {
        setToastObject({
          type: "success",
          toastMessage: response.message || "Data Successfully Submitted",
          show: true,
        });
        actions.resetForm();
      } else if (response && response.status === CONSTANT.FAIL) {
        setToastObject({
          type: "error",
          toastMessage:
            response.error || response.message || "Fail To Add Data",
          show: true,
        });
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="GHL Dashboard" />
      </Helmet>
      <ToastComponent
        open={toastObject.show}
        setOpen={(event) =>
          setToastObject({
            type: "",
            toastMessage: "",
            show: event,
          })
        }
        message={toastObject.toastMessage}
        severity={toastObject.type}
      />
      <form onSubmit={formik.handleSubmit}>
        <Card
          sx={{
            boxShadow: "none",
            borderRadius: "10px",
            p: "25px",
            mb: "15px",
          }}
        >
          <Container component="main" maxWidth="lg">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ mt: 8 }}
            >
              <Grid item xs={6}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Please Enter the Go High Level Location Id
                </Typography>
                <TextField
                  id="locationId"
                  {...formik.getFieldProps("locationId")}
                  fullWidth
                  label="Please Enter the Go High Level Location Id"
                  InputProps={{
                    style: { borderRadius: 8 },
                  }}
                />
                {formik.touched.locationId && formik.errors.locationId ? (
                  <Typography variant="h6" className="error-message">
                    {formik.errors.locationId}
                  </Typography>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Please Enter the Go High Level Code
                </Typography>
                <TextField
                  id="ghlCode"
                  type="text"
                  {...formik.getFieldProps("ghlCode")}
                  fullWidth
                  label="Please Enter the Go High Level Code"
                  InputProps={{
                    style: { borderRadius: 8 },
                  }}
                />
                {formik.touched.ghlCode && formik.errors.ghlCode ? (
                  <Typography variant="h6" className="error-message">
                    {formik.errors.ghlCode}
                  </Typography>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Please Enter the Go High Level Owner Name
                </Typography>
                <TextField
                  id="ghlOwnerName"
                  type="text"
                  {...formik.getFieldProps("ghlOwnerName")}
                  fullWidth
                  label="Please Enter the Go High Level Owner Name"
                  InputProps={{
                    style: { borderRadius: 8 },
                  }}
                />
                {formik.touched.ghlOwnerName && formik.errors.ghlOwnerName ? (
                  <Typography variant="h6" className="error-message">
                    {formik.errors.ghlOwnerName}
                  </Typography>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Please Enter the Location Url
                </Typography>
                <TextField
                  id="locationUrl"
                  type="text"
                  {...formik.getFieldProps("locationUrl")}
                  fullWidth
                  label="Please Enter the Location Url"
                  InputProps={{
                    style: { borderRadius: 8 },
                  }}
                />

                {formik.touched.locationUrl && formik.errors.locationUrl ? (
                  <Typography variant="h6" className="error-message">
                    {formik.errors.locationUrl}
                  </Typography>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <Typography
                  as="h5"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    mb: "12px",
                  }}
                >
                  Please Enter the Email Id
                </Typography>
                <TextField
                  id="emailId"
                  type="email"
                  {...formik.getFieldProps("emailId")}
                  fullWidth
                  label="Please Enter the Email Id"
                  InputProps={{
                    style: { borderRadius: 8 },
                  }}
                />

                {formik.touched.emailId && formik.errors.emailId ? (
                  <Typography variant="h6" className="error-message">
                    {formik.errors.emailId}
                  </Typography>
                ) : null}
              </Grid>

              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#0B5ED7",
                    borderColor: "blue",
                  }}
                >
                  Validate Credentials & Submit
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "green",
                    borderColor: "green",
                    alignItems: "right",
                  }}
                >
                  <Link
                    href="/access-code"
                    target="_blank"
                    className="text-white"
                  >
                    Click here to grab your GHL Code
                  </Link>
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Card>
      </form>
    </>
  );
};
export default Dashboard;
