import React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const RedirectUrl = ({ response }) => {
  const copyCode = async () => {
    navigator.clipboard.writeText(code);
  }

  const [code, setCode] = React.useState("");

  React.useEffect(() => {
    let code = new URLSearchParams(window.location.search).get('code');
    setCode(code);
  }, []);

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          borderRadius: "10px",
          p: "25px",
          mb: "15px",
        }}
      >
        <Container component="main" maxWidth="lg">
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 8 }}>
            <Grid item xs={12}>
              <Typography variant="h6"
                sx={{ color: '#212529' }}
              > <b>Your Access Code: {code} </b> <ContentCopyIcon className='cursor-pointer' onClick={() => copyCode()} />
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Card>
    </>
  )
};

export default RedirectUrl;
