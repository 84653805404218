import React from "react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { REACT_APP_FRONTEND_BASE_URL } from '../../core/constant';

const AccessCode = ({ response }) => {
  const URL = "https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=" + REACT_APP_FRONTEND_BASE_URL + "redirect_url&client_id=64120e77cbfeb8f021d1d49d-lfa0obxi&scope=locations.readonly contacts.readonly users.readonly opportunities.readonly calendars.readonly";
  console.log("URL", URL);
  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          borderRadius: "10px",
          p: "25px",
          mb: "15px",
        }}
      >
        <Container component="main" maxWidth="lg">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mt: 8 }}
          >
            <Grid item xs={12}>
              <Typography variant="h6"
                sx={{ color: '#212529' }}
              > Hello,
              </Typography>
              <Button variant="contained"
                target="_blank"  href={URL}
                sx={{ color: 'white', backgroundColor: 'green', borderColor: 'green' }}
              >
                Click here to select and get access for your LOCATION
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Card>
    </>
  );
};

export default AccessCode;
