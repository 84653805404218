import httpCommon from "../core/http.common.js";
import { API_CONSTANT } from "../core/constant.js";

export const Active_Logs_Service = {
  addActiveLogs,
  getActiveLogs,
};

function addActiveLogs(data) {
  return httpCommon
    .post(API_CONSTANT.CREATE_ACTIVE_LOGS, data)
    .then((response) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
}

function getActiveLogs(locationId) {
  return httpCommon
    .get(API_CONSTANT.GET_ACTIVE_LOGS + "/" + locationId)
    .then((response) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
}
