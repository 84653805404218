import React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import ActiveLogsComponent from "../../components/Active-Logs/ActiveLogs";
import { Helmet } from "react-helmet";
const ActiveLogs = ({ response }) => {
  const params = useParams();
  React.useEffect(() => {}, []);

  return (
    <>
      <Helmet>
          <title>Active Logs</title>
          <meta name="description" content="GHL Active Logs" />
      </Helmet>
      <Card
        sx={{
          boxShadow: "none",
          borderRadius: "10px",
          p: "25px 20px 15px",
          mb: "15px",
        }}
      >
        <Box
          sx={{
            paddingBottom: "10px",
          }}
        >
          <ActiveLogsComponent locationId={(params && params.locationId)? params.locationId : "all"} />
          {/* <DataTable /> */}
        </Box>
      </Card>
    </>
  );
};

export default ActiveLogs;
