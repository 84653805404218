import httpCommon from "../core/http.common.js";
import { API_CONSTANT } from "../core/constant.js";

export const Auth_Location_Service = {
  addAuthLocation,
  getLocationUrlByLocationId,
};

function addAuthLocation(data) {
  return httpCommon
    .post(API_CONSTANT.CREATE_AUTH_LOCATION, data)
    .then((response) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
}

function getLocationUrlByLocationId(locationId, contactId) {
  return httpCommon
    .get(API_CONSTANT.GET_LOCATION_URL + "/" + locationId + "/" + contactId)
    .then((response) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
}
